import { useState, useEffect } from "react";
import { AvatarDisplay } from "./AvatarDisplay";
import { useSelector, useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { useNavigate } from 'react-router-dom';
import { Trail } from "./TrailText";

import styles from '../../styles.module.css'
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setPlayer } from "../../store/appUser";
import { cleanMessage } from "../LiveChat/chatWindowComponents/ChatBubble";
import { ColourPicker } from "./ColourPicker";

interface WindowSize {
    width: number,
    height: number
}

type TextState = {
    text1: boolean,
    text2: boolean,
    shown: boolean
}

type Props = {
    setCanJumpIn: Function 
}
export const AvatarPage: React.FC<Props> = ({setCanJumpIn}) => {

    const history = useNavigate();
    let dispatch = useDispatch();
    const [windowSize, setWindowSize] = useState<WindowSize>({
        width: window.innerWidth,
        height: window.innerHeight
      });
    const {player} = useAppSelector((state) => state.appUser)
    const {className} = useAppSelector((state) => state.scene)
    const {connected} = useAppSelector(state => state.web3)
    const [open, set] = useState<TextState>({text1: false, text2: false, shown: false})
    const [prompt, setprompt] = useState<string>("")

    // useEffect(() => {
    //     let cookie = Cookies.get("avatarUrl")
    //     if(cookie){
    //         dispatch(setAvatarGlb(cookie))
    //         handleShowAvatar(cookie)
    //     }
    // })
    
    useEffect(() => {
        const handleResize = () => {
          setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight
          });
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    function handleShowAvatar(glb: string){
        let stateToUpdate = {
            ...player,
            glbUrl: glb, 
        };
        dispatch(setPlayer(stateToUpdate))
        //Cookies.set("avatarUrl", glb);
    }

    function handleJumpIn(){
        if(player.glbUrl !== null && player.displayName !== null){
            if( player.displayName.length === 0){
                setprompt("prompt")
                setTimeout(() => {
                    setprompt("")
                }, 2000)
            } else {
                setCanJumpIn(true)
                history("/mv/live/" + className)
            }
        } else {
            setprompt("prompt")
            setTimeout(() => {
                setprompt("")
            }, 2000)
        }
    }

    function handleAvatarName(name:string){
        let clean = cleanMessage(name)
        const urlRegex = /(https?:\/\/[^\s]+|www\.[^\s]+)/g;
        if(urlRegex.test(clean)){
            clean = clean.replace(urlRegex, "*")
        }
        let stateToUpdate = {
            ...player,
            displayName: clean, 
        };
        dispatch(setPlayer(stateToUpdate))
        Cookies.set("username", clean)
    }


    return (
        <div className="avatar-background">
            <div className="header">
                <h1 className="headings">Avatar Selection</h1>
            </div>
            <div className="avatar-input-area1">
                <h3 className="headings">Whats your Name?</h3>
                <div>
                    <input 
                        className="custom-input" 
                        placeholder="Enter A Name" 
                        value={player.displayName ? player.displayName : ""} 
                        disabled={!connected}
                        onChange={e => { 
                            handleAvatarName(e.target.value)
                            //Cookies.set("username", e.target.value)
                        }}></input>
                </div>
                <div style={{width: '50%', float: 'right'}}>
                    <button className="button-85" disabled={!connected} onClick={() => handleJumpIn()}>Jump In</button>
                    <ColourPicker  position='relative' top='15px' right='100px' buttonClass='colour-btn'/>
                </div>
            </div>
            <div className={`avatar-input-area2 ${prompt}`} >
                <h4 className="headings">Select A Default Avatar Body Type: </h4>
                <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    
                    <button className="button-84" disabled={!connected} onClick={() => handleShowAvatar("https://models.readyplayer.me/6641ec2fd5d11dcc57df141d.glb")}>Default Female</button>
                    
                    <button className="button-84" disabled={!connected} onClick={() => handleShowAvatar("https://models.readyplayer.me/66c870b093f9fe518a518f81.glb")}>Default Male</button>
                    
            </div>
            <h4 className="headings">Or Create Your Own :</h4> 
            <div style={{ marginTop: '5%', width: '100%' }}>
                <button className="button-84" disabled={!connected} onClick={() => history("/mv/rpm")}>Enter Editor</button>
            </div>
           
                
            </div>
            
            
            <AvatarDisplay />

            
        </div>
    )
}