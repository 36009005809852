import { Route, Routes, useNavigate } from 'react-router-dom';
import './App.css';
import { Fragment, useEffect } from "react";
import { UnityClient } from './components/UnityClient';
import { InboundLinkRouting } from './components/InboundLinkRouting';
import ReadyPlayerMeCreator from './components/ReadyPlayerMe/ReadyPlayerMeCreator';
import WelcomePage from './components/LandingPage/WelcomePage';
import { AvatarPage } from './components/AvatarPage/AvatarPage';
import { useAppDispatch, useAppSelector } from './store/hooks';
import { LoginPage } from './components/LandingPage/LoginPage';
import ProtectedRoute from './Utils/ProtectedRoute';
import { setShowAlert } from './store/appUser';
import { AlertModal } from './components/AlertModal';
import { setConnected, setWalletAddress } from './store/web3';
import { ScenePage } from './components/ScenePages/ScenePage';

const App: React.FC = () => {

  const { scenes } = useAppSelector(state => state.scene)
  const { connected, walletAddress } = useAppSelector(state => state.web3)
  const { showAlert } = useAppSelector(state => state.appUser)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        console.log('Tab is inactive');
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);


  useEffect(() => {
    if (window.ethereum) {
      const handleAccountsChanged = (accounts: unknown) => {
        if (Array.isArray(accounts) && accounts.every((acc) => typeof acc === 'string')) {
          if (accounts[0] !== walletAddress && accounts.length > 1) {
            dispatch(setShowAlert(true))
          }
          if (accounts.length === 0) {
            dispatch(setConnected(false))
          }
        }
        else {
          console.warn("Unexpected accounts format:", accounts);
        }
      };

      // Register the event listener
      window.ethereum?.on("accountsChanged", handleAccountsChanged);

      // Cleanup event listener
      return () => {
        if (window.ethereum) {
          window.ethereum.removeListener(
            "accountsChanged",
            handleAccountsChanged
          );
        };
      }
    }
  }, []);

  return (
    <Fragment>
      <AlertModal open={showAlert} />
      <Routes>
        <Route
          path="/"
          element={
            <LoginPage />}
        />
        <Route
          path="/home"
          element={
            <WelcomePage />}
        />
        {
          scenes.map(scene => {
            return <Route
              path={`/mv/live/${scene.className}`}
              element={
                <InboundLinkRouting scene={scene} scenePage={true} />
              }
            />
          })
        }
        {
          scenes.map(scene => {
            return <Route
              path={`${scene.url}`}
              element={
                <ProtectedRoute loggedIn={connected} element={<InboundLinkRouting scene={scene} scenePage={false} />} />}
            />
          })
        }
        {
          scenes.map(scene => {
            return <Route
              path={`${scene.url}/:id`}
              element={
                <ProtectedRoute loggedIn={connected} element={<InboundLinkRouting scene={scene} scenePage={false} />} />}
            />
          })
        }
        {
          scenes.map(scene => {
            return <Route
              path={`${scene.infoPageUrl}`}
              element={
                <ScenePage scene={scene} />
              }
            />
          })
        }
        <Route
          path="/mv/rpm"
          element={
            <ReadyPlayerMeCreator
              style={{ width: "100vw", height: "100vh", border: "none", margin: 0, zIndex: 4500000, borderRadius: 'none' }}
              width='100vw'
              height='100vh'
              close={null}
              isModal={false}
              sendMessage={null}
            />
          }
        />

      </Routes>
    </Fragment>
  );
}

export default App;
