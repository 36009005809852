import { useEffect, useState } from "react"
import { RouteParams, UnityClient } from "./UnityClient";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import { AvatarPage } from "./AvatarPage/AvatarPage";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { setPlayer } from "../store/appUser";
import { Scene, setClassName, setCode, setData, setFramework, setLoader, setModuleId, setName, setSceneUrl, setStreamingAssets } from "../store/scenes";
import { ScenePage } from "./ScenePages/ScenePage";

export const globalIsHost = {
    isHost: false
} 

type Props = {
    scene: Scene,
    scenePage: boolean
}

export const InboundLinkRouting: React.FC<Props> = ({scene, scenePage}) => {

    const {id} = useParams<RouteParams>();
    const { connected } = useAppSelector(state =>  state.web3)
    const [hasCookie, setHasCookie] = useState(false);
    const [sceneLoaded, setSceneLoaded] = useState(false);
    const hostId = "b2124128-a607-487f-b9de-33b03750cab9";
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setLoader(scene.loader))
        dispatch(setData(scene.data))
        dispatch(setFramework(scene.framework))
        dispatch(setCode(scene.code))
        dispatch(setStreamingAssets(scene.streamingAssests))
        dispatch(setName(scene.name))
        dispatch(setModuleId(scene.moduleId))
        dispatch(setClassName(scene.className))
        dispatch(setSceneUrl(scene.url))
        setSyle()
        setSceneLoaded(true)
    },[])

    const setSyle = () => {
        document.documentElement.style.setProperty('--background-avatar', `url(${scene.style.background})`);
        document.documentElement.style.setProperty('--primary-color', scene.style.primary_color);
        document.documentElement.style.setProperty('--secondary-color', scene.style.secondary_color);
        document.documentElement.style.setProperty('--accent-color', scene.style.accent_color);
        document.documentElement.style.setProperty('--highlight-color', scene.style.highlight_color);
        document.documentElement.style.setProperty('--error-color', scene.style.error_color);
        document.documentElement.style.setProperty('--background-avata', scene.style.text);
        document.documentElement.style.setProperty('--text-color', scene.style.text);
    }

    // useEffect(() => {
    //     let avatarUrl = Cookies.get("avatarUrl");
    //     let username = Cookies.get("username");

    //     if (avatarUrl === undefined || username === undefined) {
    //         setHasCookie(false);
    //     }
    // }, []);


    if(id === hostId){
        globalIsHost.isHost = true
    }

    if(scenePage){
        return(
            <>
               { connected ? <UnityClient /> : <ScenePage scene={scene}/>}
            </>
        )
    }

    return(
        <>
             {sceneLoaded && (
                hasCookie ? <UnityClient /> : <AvatarPage setCanJumpIn={setHasCookie}/>
            )}
        </>
    )
}